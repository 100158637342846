import {Injectable} from '@angular/core';
import {NotificationService} from "./notification.service";
import {Subject} from "rxjs";
import {AuthenticationService} from "./authentication.service";
import {Router} from "@angular/router";
import {UserInfo} from "../interfaces/user-crendential.interface";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {
  ProjectConfirmDialogComponent
} from "../../modules/project/components/project-confirm-dialog/project-confirm-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private isApp = false;
  private expoToken = "";
  expoTokenObs$: Subject<any> = new Subject();
  private miniMumAppVersion = 29;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private authService: AuthenticationService,
    private dialog: MatDialog,
  ) {
  }

  appAdapter(messageFromApp: string) {
    try {
      const msg = JSON.parse(messageFromApp);
      this.isApp = true;
      if (msg.action === "registerExpoToken") {
        this.registerExpoToken(msg.data.expoToken);
      } else if (msg.action === "getAuthToken") {
        this.updateAuthTokenRequest();
      } else if (msg.action === "navigateToMsg") {
        this.router.navigate(['/notification/received'])
      } else if (msg.action === "raiseExpoTokenRequest") {
        this.registerExpoTokenRequest();
      } else if (msg.action === "deepLinkingNavigation") {
        // alert(`From Angular: ${msg.data.url}`)
        if (msg.data.url) {
          this.router.navigateByUrl(`${msg.data.url}`)
        } else {
          this.authService.isLoggedIn() ? this.router.navigateByUrl(`/project/list`) : this.router.navigateByUrl(`/auth/login`)
        }
      } else if (msg.action === 'appVersionFromRN') {
        if (Number(msg.data.version) < this.miniMumAppVersion) {
          this.dialog.open(ProjectConfirmDialogComponent, {
            data: {
              title: 'Update Required',
              message: `Please update your RealtyCMS (${msg.data.versionIOS}) to the latest version 1.3.1 APP from Apple Store or Google Play.`
            },
          });
          // alert(`Please update your RealtyCMS (${msg.data.versionIOS}) to the latest version 1.3.1 APP from Apple Store or Google Play.`)
        }
      }
    } catch (e) {
    }
  }

  appRequest(msgObj: any) {
    // @ts-ignore
    (window['ReactNativeWebView'] || window).postMessage(JSON.stringify(msgObj));
  }

  angularAdapterRequest(msgObj: any) {
    this.appRequest({
      action: "AngularAdapter",
      data: msgObj
    })
  }

  getExpoToken() {
    this.angularAdapterRequest({action: "getExpoToken"})
    return this.expoTokenObs$
  }

  registerExpoToken(token: string) {
    this.expoToken = token;
    this.expoTokenObs$.next(token);
  }

  deRegisterExpoToken() {
    this.expoToken = "";
    this.expoTokenObs$.next(this.expoToken);
  }

  registerExpoTokenRequest(token: string = "") {
    if (!!token) {
      this.appRequest({
        action: "RegisterExpoToken",
        data: {authToken: token}
      })
    } else {
      if (!this.authService.isLoggedIn()) {
        this.deRegisterExpoTokenRequest()
      } else {
        this.appRequest({
          action: "RegisterExpoToken",
          data: {authToken: this.authService.getAccessToken()}
        })
      }
    }
  }

  updateAuthTokenRequest() {
    this.appRequest({
      action: "UpdateAuthToken",
      data: {authToken: this.authService.getAccessToken()}
    })
  }

  deRegisterExpoTokenRequest() {
    this.appRequest({
      action: "DeRegisterExpoToken",
      data: {authToken: "__NO__TOKEN__"}
    })
  }

  openImageViewer(imgObj: any) {
    this.appRequest({
      action: "ImageViewer",
      data: imgObj
    })
  }

  openLinkingView(url: string) {
    this.appRequest({
      action: "OpenLinking",
      data: {url: url}
    })
  }

  openIframeViewer(url: string, shareTitle: string, shareUrl: string = "", category: string = "iframe", extra: any = {}) {
    this.appRequest({
      action: "iframeViewer",
      data: {
        url: url,
        shareTitle: shareTitle,
        shareUrl: shareUrl,
        category: category,
        extra: extra
      }
    })
  }

  openLinkingInternalViewer(url: string, shareTitle: string, screenTitle: string, shareUrl: string = "", category: string = "iframe", extra: any = {}) {
    this.appRequest({
      action: "OpenLinkingInternal",
      data: {
        url: url,
        shareTitle: shareTitle,
        screenTitle: screenTitle,
        shareUrl: shareUrl,
        category: category,
        extra: extra
      }
    })
  }

  openProjectView(projectId: string) {
    const currentAuth = this.authService.getCurrentUserValueString();
    this.appRequest({
      action: "projectViewer",
      data: {
        projectId: projectId,
        currentUserAuth: currentAuth
      }
    })
  }

  openProjectProposal(projectProposal: any) {
    this.appRequest({
      action: "ShareProposal",
      data: projectProposal
    })
  }

  displayProjectProposalButton(projectProposal: any) {
    this.appRequest({
      action: "DisplayShareProposal",
      data: projectProposal
    })
  }

}
